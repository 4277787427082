import React, { FC } from 'react';

import Links from '../../components/pagesComponents/CaseStudy/Links';
import SectionTitle from '../../components/common/SectionTitle';
import TextDescription from '../../components/common/TextDescription';
import FreshiiLayout from '../../components/PagesLayouts/FreshiiLayouts';
import PeolpeWhoMade from '../../components/pagesComponents/CaseStudy/PeolpeWhoMade';

import YuriiDrozd from '../../assets/img/aboutUs/staff/PM/YuriiDrozd.jpeg';
import profileIcon from '../../assets/img/CaseStudy/Freshii/staff/profile-icon.jpg';
import OleksiiHnatiienko from '../../assets/img/aboutUs/staff/PM/OleksiiHnatiienko.png';
import AntonShloma from '../../assets/img/aboutUs/staff/Developers/AntonShloma.jpeg';
import DenisKlymenko from '../../assets/img/CaseStudy/Freshii/staff/DenisKlymenko.png';
import VitaliiDokalenko from '../../assets/img/CaseStudy/Freshii/staff/VitaliiDokalenko.png';
import BohdanVorobiovskyi from '../../assets/img/CaseStudy/Freshii/staff/BohdanVorobiovskyi.png';
import EgorShloma from '../../assets/img/CaseStudy/Freshii/staff/EgorShloma.png';
import MykhayloGnylorybov from '../../assets/img/CaseStudy/Freshii/staff/MykhayloGnylorybov.png';
import AndrewBogaevskyi from '../../assets/img/CaseStudy/Freshii/staff/AndrewBogaevskyi.png';
import SashaKozlov from '../../assets/img/CaseStudy/Freshii/staff/SashaKozlov.png';
import MaksymMartyniuk from '../../assets/img/CaseStudy/Freshii/staff/MaksymMartyniuk.png';
import RuslanaChumachenko from '../../assets/img/CaseStudy/Freshii/staff/RuslanaChumachenko.png';
import YuliiaYazenok from '../../assets/img/CaseStudy/Freshii/staff/YuliiaYazenok.png';
import DmytroBohdan from '../../assets/img/CaseStudy/Freshii/staff/DmytroBohdan.jpeg';
import DenysTomakhin from '../../assets/img/CaseStudy/Freshii/staff/DenysTomakhin.jpeg';

import { TOurTeam, TText } from '../../types/types';

import './FreshiiCaseStudy.scss';
import FixedButton from '../../components/common/FixedButton';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const FreshiiCaseStudy: FC = () => {
    const collaborationText: TText = [
        {
            id: 0,
            text:
                "Freshii needed a technology partner who could deliver a custom platform that met their complex needs, and that's where Blackbird came in. We assembled a team of experts who took on the entire technical component of the project, from architecture and development to deployment and maintenance: Technical project manager who drove the execution, three back-end developers, two iOS developers, two Android developers, one Web developer, an Architect on a part-time basis, DevOps, and two Quality Assurance specialists.",
            margin: 'mb',
        },
        {
            id: 1,
            text:
                "We worked closely with Freshii's product owner and designer to understand their vision and refine the requirements. We then built a flexible, scalable platform that supported integration with third-party platforms like OLO and Order House, which allowed Freshii to route orders to points of sale and store loyalty data, respectively.",
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Over the course of several months, we developed three applications on the front end - Web(Javascript), iOS(Swift), and Android(Kotlin) - and a microservice architecture on the Java back end in Amazon Cloud(ECS). We also used GraphQL for integration, enabled posts card payments, including Apple and Google Pay, and menu management. After the release, we continued to build new features, giving Freshii complete flexibility to extend the platform as they saw fit.',
            margin: 'mb',
        },
    ];
    const ourTalanted: TOurTeam = [
        { id: 0, name: 'Yurii D.', position: 'CEO', img: YuriiDrozd },
        { id: 1, name: 'Oleksii H.', position: 'TPM', img: OleksiiHnatiienko },
        { id: 2, name: 'Anton S.', position: 'Software Engineer', img: AntonShloma },
        { id: 3, name: 'Denys K.', position: 'Software Engineer', img: DenisKlymenko },
        { id: 4, name: 'Vitalii D.', position: 'Software Engineer', img: VitaliiDokalenko },
        // { id: 5, name: 'Bohdan V.', position: 'Software Engineer', img: BohdanVorobiovskyi },
        { id: 6, name: 'Egor S.', position: 'Software Engineer', img: EgorShloma },
        // { id: 7, name: 'Vladimir K.', position: 'Software Engineer', img: profileIcon },
        // { id: 8, name: 'Sergey K.', position: 'Software Engineer', img: profileIcon },
        { id: 9, name: 'Dmytro B.', position: 'Software Engineer', img: DmytroBohdan },
        { id: 10, name: 'Mykhaylo G.', position: 'Software Engineer', img: MykhayloGnylorybov },
        { id: 11, name: 'Andrew B.', position: 'Software Engineer', img: AndrewBogaevskyi },
        // { id: 12, name: 'Oleksandr S.', position: 'Software Engineer', img: profileIcon },
        { id: 13, name: 'Sasha K.', position: 'Software Engineer', img: SashaKozlov },
        // { id: 14, name: 'Valerii L.', position: 'Software Engineer', img: profileIcon },
        { id: 15, name: 'Maksym M.', position: 'QA', img: MaksymMartyniuk },
        { id: 16, name: 'Ruslana C.', position: 'QA', img: RuslanaChumachenko },
        { id: 17, name: 'Yuliia Y.', position: 'QA', img: YuliiaYazenok },
        { id: 18, name: 'Denys T.', position: 'DevOps', img: DenysTomakhin },
    ];

    const beginingStart: TText = [
        {
            id: 0,
            text:
                "Freshii, a popular health-food restaurant chain, needed help, like many other businesses in the industry, to build a custom platform to support their web and mobile channels. Their previous attempts had failed, and they were looking for a technology partner to help them bring their vision to life. That's where Blackbird came in.",
            margin: 'mb',
        },
    ];

    const generationsText: TText = [
        {
            id: 0,
            text:
                'Freshii had tried to build its own custom platform three times, but each attempt had failed. Freshii needed a custom platform to support web, iOS, and Android channels and route orders to hundreds of stores with point-of-sale terminals. They needed a flexible platform to allow for updates and complex logic. The old mobile application had 350,000 registered users, and Freshii wanted to retain them.',
            margin: 'mb',
        },
    ];

    const summaryText: TText = [
        {
            id: 0,
            text:
                'Thanks to the custom platform we built, Freshii processed over $1 million in monthly online payments for orders purchased through their food app.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                "Overall, the collaboration between Freshii and Blackbird was a great success. We built a custom platform that met Freshii's complex needs and helped them achieve their business goals. Creating a custom platform for Freshii was challenging but rewarding. Working closely with Freshii's team and leveraging our expertise, we were able to deliver a platform. ",
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Brands and restaurants must observe market leaders investing in technology and building their digital experience and loyalty programs to improve customer relationships. It is essential to have a loyal customer base to make the restaurant business predictable and controlled. If they have these tools and know their clients, they are enabled to rapid growth.',
            margin: 'mb',
        },
    ];

    useScrollToTop();

    return (
        <FreshiiLayout>
            <div className="statflo-case-study__text">
                <SectionTitle type="primary" title="The story" />
                {beginingStart.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="Background" />
                {generationsText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="Collaboration" />
                {collaborationText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="Summary" />
                {summaryText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Links />
            </div>
            <PeolpeWhoMade page="/freshii-case-study/full-credits/" ourTalanted={ourTalanted} />
            <FixedButton />
        </FreshiiLayout>
    );
};

export default FreshiiCaseStudy;
